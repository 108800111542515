import Page from '../Page/Page'
import classes from './Cart.module.css'
import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { Loader } from '../../components/loader/Loader'
import { Await, useLoaderData } from 'react-router-dom'
import { ErrorElement } from '../../components/errorElement/ErrorElement'
import { IItemValue } from '../../interfaces/Item'
import { ItemsGroup } from '../../components'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { priceWithSpace } from '../../utils/priceWithSpace'
import { CartCard } from '../../components/cartCard/CartCard'
import { useDispatch } from 'react-redux'
import { cartActions } from '../../store/reducers/cartReducer'
import { ICart } from '../../interfaces/Cart'
import { IProcessSale } from '../../interfaces/Order'
import { Helmet } from 'react-helmet-async'

type PromiseType = {
    hitProducts: Promise<IItemValue>
}

export type SetPromocodeType = {
    setPromocodeResp: React.Dispatch<React.SetStateAction<IProcessSale | undefined>>
}

const Cart = () => {
    const { hitProducts } = useLoaderData() as PromiseType

    const { cart } = useTypedSelector((state) => state.cartReducer)
    const dispatch = useDispatch()

    const [promocodeResp, setPromocodeResp] = useState<IProcessSale>()

    const getPriceElement = (product: ICart): JSX.Element => {
        const code = product.modifiers ? product.modifiers.article : product.product.article
        let price = 0
        let discountPrice = 0

        if (promocodeResp) {
            const p = promocodeResp._bill_data.items.find((i) => i.code === code)
            // console.log(promocodeResp, p)
            if (p) {
                price = p.total
                discountPrice = p.discounted_total
            }
        } else {
            if (product.modifiers) {
                discountPrice =
                    product.modifiers.priceSale > 0
                        ? product.modifiers.priceSale
                        : product.modifiers.priceDefault
                discountPrice *= product.amount

                price = product.modifiers.priceDefault * product.amount
            } else {
                discountPrice =
                    product.product.priceSale > 0
                        ? product.product.priceSale
                        : product.product.priceDefault
                discountPrice *= product.amount

                price = product.product.priceDefault * product.amount
            }
        }

        if (discountPrice !== price) {
            return (
                <p className={classes.price}>
                    {priceWithSpace(discountPrice)}₽{' '}
                    <span className={classes.priceSale}>{priceWithSpace(price)}₽</span>
                </p>
            )
        }

        return <p className={classes.price}>{priceWithSpace(price)}₽</p>
    }

    const isShowBanner = useMemo(() => {
        const count = cart.reduce((acc, item) => {
            if (item.product.categories.find((i) => i._id === '63526fba845f00f43c5597ee')) {
                acc += item.amount
            }
            return acc
        }, 0)
        return count === 2
    }, [cart])

    return (
        <Page hideMiniCart>
            <Helmet>
                <title>Корзина товаров</title>
                <link rel='canonical' href='https://parfumeratelier.ru/cart' />
                <meta property='og:title' content='Корзина товаров' />
                <meta property='og:url' content='https://parfumeratelier.ru/cart' />
            </Helmet>
            <div className={classes.cart}>
                <div className={classes.products}>
                    {cart.map((p) =>
                        p.product.slug !== 'dostavka' ? (
                            <div key={p._id} className={classes.product}>
                                <img src={p.product.imgUrl[0]} alt={p.product.name} />
                                <h3 className={classes.name}>
                                    {p.product.name}
                                    <br />
                                    <small>{p.modifiers && `${p.modifiers.name} мл`}</small>
                                </h3>
                                <div className={classes.count}>
                                    <button
                                        className={classes.dec}
                                        onClick={() => {
                                            dispatch(cartActions.decreaseCount(p))
                                        }}>
                                        &ndash;
                                    </button>
                                    <span>{p.amount}</span>
                                    <button
                                        className={classes.inc}
                                        onClick={() => {
                                            dispatch(cartActions.increaseCount(p))
                                        }}>
                                        +
                                    </button>
                                </div>
                                <div className={classes.priceBlock}>{getPriceElement(p)}</div>
                            </div>
                        ) : null
                    )}
                    {cart.length === 0 && <p>Корзина пуста</p>}
                    {isShowBanner && (
                        <div className={classes.Banner}>
                            <p>
                                Добавь ещё 1 аромат и получи скидку 10% на заказ<span>*</span>
                            </p>
                            <small>
                                <span>*</span>участвуют только товары из категории "парфюмерия"
                            </small>
                        </div>
                    )}
                    <Suspense fallback={<Loader />}>
                        <Await
                            resolve={hitProducts}
                            errorElement={<ErrorElement msg='Не удалось загрузить рекомендации' />}>
                            {(bundles: IItemValue) => {
                                return (
                                    <ItemsGroup
                                        title='Рекомендуем'
                                        items={bundles.values}
                                        perPage={4}
                                    />
                                )
                            }}
                        </Await>
                    </Suspense>
                </div>
                <CartCard setPromocodeResp={setPromocodeResp} />
            </div>
        </Page>
    )
}

export default Cart
